import produce from "immer";
import { FormEvent, useState } from "react";
import { SchemaDefinitionFormRequest, SchemaDefinitionItemFormRequest } from "../gateways/cms/schema";
import { SchemaDefinitionItemEditModal } from "./SchemaDefinitionItemEditModal";
import { SelectOrganization } from "./SelectOrganization";
import { SelectOrganizationModal } from "./SelectOrganizationModal";
import { SelectUserGroup } from "./SelectUserGroup";
import { SelectUserGroupModal } from "./SelectUserGroupModal";

import { styled } from "@mui/system";
import VarConst from "../styles/variables.js";
import Color from "../styles/colors.js";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import { SchemaItemMain } from "./SchemaItemMain";
import iconPlus from "../assets/icons/icon_plus.svg";
import iconDelete from "../assets/icons/icon_delete.svg";
import iconEdit from "../assets/icons/icon_pen.svg";

// style
const SectionComon = styled("section")`
    h2 {
        margin: 0 0 24px;
        padding: 10px 10px 10px 40px;
         height: 40px;
        font-weight: normal;
        font-size: 20px;
        background-color: #f4f4f4;
        line-height: 1;
    }
    h2 + div {
        margin: 24px 24px 48px;

        input,
        select {
            height: 32px;
            border: ${VarConst.border1};
        }

        input[type="date"],
        input[type="time"] {
            padding-left: 0.5em;
        }

        label {
            display: flex;
            margin-bottom: 8px;

            & ~ input[type="text"],
            & + select {
                width: 100%;
            }
            & input[type="date"] {
                width: 12em;
            }
            & input[type="time"] {
                width: 6em;
            }
        }

        & > ul:nth-of-type(1) {
            display: grid;
            width: 100%;
            grid-template-columns: calc(180px + 24px) calc(180px + 24px) 1fr;

            li {
                margin-bottom: 24px;
                padding-right: 24px;

                &:nth-of-type(1) {
                    grid-column: 1/2;
                }
                &:nth-of-type(2) {
                    grid-column: 1/3;
                }
                &:nth-of-type(3) {
                    grid-column: 1/4;
                }
                &:nth-of-type(4) {
                    grid-column: 1/4;
                }

                select {
                    width: 180px;
                    margin-right: 24px;
                    margin-bottom: 8px;
                }
            }
        }
    }
`;
const ButtonSq = styled(Button)`
    &.MuiButtonBase-root {
        height: 32px;
        width: 32px;
        min-width: 32px;
        border-width: 2px;
        border-radius: 2px;

        color: transparent;
        overflow: hidden;
        user-select: none;
        cursor: pointer;

        background-color: #fff;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;

        * {
            color: transparent;
            white-space: nowrap;
        }
    }
`;
const ButtonDelete = styled(ButtonSq)`
    &.MuiButtonBase-root {
        border-width: 2px;
        background-image: url(${iconDelete});
        border-color: transparent;
    }
`;
const ButtonEdit = styled(ButtonSq)`
    &.MuiButtonBase-root {
        background-image: url(${iconEdit});
        background-size: 75%;
    }
`;
const ButtonPlus = styled(ButtonSq)`
    &.MuiButtonBase-root {
        display: block;
        margin: 0 auto;
        background-image: url(${iconPlus});
        background-size: 62.5%;
        background-color: #3a3e5d;
    }
`;
const ButtonFooter = styled(Button)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        width: 116px;
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #c9cbd3;
    }
    &.MuiButton-contained {
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
    }
    &.destructive {
        color: #fd6f6c;
        width: auto;
    }
`;

const SchemaItem = styled("section")`
    position: relative;
    min-height: 64px;
    padding: 24px;
    margin-bottom: 24px;
    border: ${VarConst.border1};
    border-radius: 1px;

    .buttons {
        position: absolute;
        top: 16px;
        right: 16px;

        button + button {
            margin-left: 8px;
        }
    }
`;
const BarFooter = styled("footer")`
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 16px 24px;
    background-color: ${Color.bgHead};

    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100vw - 260px);

    & > div {
        align-items: end;

        div {
            display: flex;
            flex-wrap: wrap;
            width: 30em;

            label {
                line-height: 32px;
                margin-right: 1em;
                margin-bottom: 8px;
            }
            input[type="text"] {
                width: 100%;
                height: 32px;
            }
        }
    }
    button {
        width: 116px;
        height: 32px;
    }
`;

// [コンテンツスキーマ管理(編集)] フォーム
export interface Props {
    data?: SchemaDefinitionFormRequest;
    onSubmit: (data: SchemaDefinitionFormRequest) => unknown;
    onDelete?: () => unknown;
}

export function SchemaDefinitionForm({ data, onSubmit, onDelete }: Props) {
    const [title, setTitle] = useState(data?.title || "");
    const [organizations, setOrganizations] = useState(data?.organization || []);
    const [userGroups, setUserGroups] = useState(data?.usergroup || []);
    const [items, setItems] = useState(data?.schemaItem || []);
    const [organizationEditModalOpen, setOrganizationEditModalOpen] = useState(false);
    const [userGroupEditModalOpen, setUserGroupEditModalOpen] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

    // スキーマ設定をモーダルで開かないといけないので状態管理する
    const selectedItem = selectedItemIndex < 0 ? undefined : items[selectedItemIndex];

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            id: data?.id,
            title,
            organization: organizations,
            usergroup: userGroups,
            schemaItem: items,
        });
    };

    const addItem = () => {
        setItems([...items, { title: "スキーマ項目" }]);
    };

    const editItem = (index: number) => {
        setSelectedItemIndex(index);
    };

    const changeItem = (data: SchemaDefinitionItemFormRequest) => {
        setItems(
            produce(items, (draft) => {
                draft[selectedItemIndex] = data;
            })
        );
        resetSelectedItem();
    };

    const resetSelectedItem = () => {
        setSelectedItemIndex(-1);
    };

    const deleteItem = (index: number) => {
        setItems(
            produce(items, (draft) => {
                draft.splice(index, 1);
            })
        );
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <SectionComon>
                    <h2>基本情報</h2>
                    <div>
                        <ul>
                            <li>
                                <label htmlFor="id">管理ID</label>
                                <input type="text" value={data?.id || undefined} disabled />
                            </li>
                            <li>
                                <label htmlFor="title">コンテンツタイプ名</label>
                                <input
                                    type="text"
                                    value={title}
                                    id="title"
                                    required
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </li>
                            <li>
                                <label htmlFor="organizations">利用可能テナント</label>
                                {organizations.map((organization) => (
                                    <SelectOrganization key={organization.id} value={organization.id} disabled />
                                ))}
                                <ButtonEdit type="button" onClick={() => setOrganizationEditModalOpen(true)}>
                                    編集
                                </ButtonEdit>
                            </li>
                            <li>
                                <label htmlFor="userGroups">利用可能ユーザーグループ</label>
                                {userGroups.map((userGroup) => (
                                    <SelectUserGroup key={userGroup.id} value={String(userGroup.id)} disabled />
                                ))}
                                <ButtonEdit type="button" onClick={() => setUserGroupEditModalOpen(true)}>
                                    編集
                                </ButtonEdit>
                            </li>
                        </ul>
                    </div>
                </SectionComon>

                <SectionComon>
                    <h2>スキーマ設定</h2>
                    <div>
                        {items.map((item, i) => (
                            <SchemaItem key={i}>
                                <SchemaItemMain schemaData={item} />

                                <div className="buttons">
                                    <ButtonEdit type="button" onClick={() => editItem(i)}>
                                        編集
                                    </ButtonEdit>
                                    <ButtonDelete type="button" onClick={() => deleteItem(i)}>
                                        削除
                                    </ButtonDelete>
                                </div>
                            </SchemaItem>
                        ))}

                        <ButtonPlus type="button" onClick={addItem}>
                            {/* スキーマ設定を */}追加
                        </ButtonPlus>
                    </div>
                </SectionComon>

                <BarFooter>
                    <div>
                        {onDelete && (
                            <ButtonFooter type="button" variant="outlined" className="destructive" onClick={onDelete}>
                                スキーマを削除
                            </ButtonFooter>
                        )}
                    </div>

                    <Stack spacing={2} direction="row">
                        <ButtonFooter type="button" variant="outlined" href="/schema-definitions">
                            キャンセル
                        </ButtonFooter>
                        <ButtonFooter type="submit" variant="contained" disableElevation>
                            保存
                        </ButtonFooter>
                    </Stack>
                </BarFooter>
            </form>

            <SchemaDefinitionItemEditModal data={selectedItem} onSubmit={changeItem} onDismiss={resetSelectedItem} />
            <SelectOrganizationModal
                open={organizationEditModalOpen}
                value={organizations}
                onChange={setOrganizations}
                onClose={() => setOrganizationEditModalOpen(false)}
            />
            <SelectUserGroupModal
                open={userGroupEditModalOpen}
                value={userGroups}
                onChange={setUserGroups}
                onClose={() => setUserGroupEditModalOpen(false)}
            />
        </>
    );
}
