import { ChangeEventHandler, useState } from "react";

import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg";

// style
const FilterMenuGrid = styled("section")`

    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;

    & > header,
    & > footer {
        display: flex;
        padding: 4px;
        justify-content: space-evenly;

        button {
            flex: 1;
            padding: 6px 0;
            white-space: nowrap;
            min-width: 80px;
        }
    }
    & > footer {
        padding: 12px;
    }
    & > div {
        padding: 8px 16px;
        flex: 1;
        overflow: auto;

        label {
            display: block;
            margin-bottom: 12px;
            cursor: pointer;
            position: relative;

            input[type="checkbox"] {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 24px;
                height: 24px;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                transform: translate( -50%, -50%);

                & + span{
                    display: flex;
                    align-items: center;

                    &::before {
                        content: "";
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        margin-right: 0.5em;
                        pointer-events: none;
                        background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                    }
                }
                &:checked + span::before {
                    background-image: url(${iconCheckboxOn});
                }
            }
        }
    }
`;

const ButtonFooter = styled(Button)`
    height: 32px;
    width: 116px;
    border-radius: 2px;
    padding: 4px;

    &.MuiButton-contained {
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
    }
    &.MuiButton-outlined {
        color: inherit;
        background-color: #fff;
        border-width: 2px;
        border-color: #c9cbd3;
    }

    & + button {
        margin-left: 8px;
    }
`

export interface Option {
    value: string;
    label: string;
}

export interface Props {
    options: Option[];
    value: string[];
    onChange: (value: string[]) => void;
}

export function SelectFilter({ options, value, onChange }: Props) {
    const [current, setCurrent] = useState(value);

    const allOptions = options.map((v) => String(v.value));

    const select: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.checked) {
            setCurrent([...current, e.target.value]);
        } else {
            setCurrent(current.filter((v) => v !== e.target.value));
        }
    };
    const selectAll = () => {
        setCurrent(allOptions);
    };
    const unselectAll = () => {
        setCurrent([]);
    };

    const handleDismiss = () => {
        unselectAll();
        onChange([]);
    };

    const handleSubmit = () => {
        onChange(current);
    };

    return (
        <FilterMenuGrid>
            <header>
                <Button type="button" onClick={selectAll}>
                    すべて選択
                </Button>
                <Button type="button" onClick={unselectAll}>
                    選択解除
                </Button>
            </header>
            <div>
                {options.map((option) => (
                    // 課題 NEXT_SIGNAGE_CMS-119: コンテンツ管理の 削除の選択肢を非表示
                    (option.label === "削除" && option.value === "99") 
                    ? <></>
                    : <>
                        <label key={option.value}>
                            <input
                                type="checkbox"
                                value={option.value}
                                checked={current.includes(option.value)}
                                onChange={select}
                            />
                            <span>
                                {option.label}
                            </span>
                        </label>
                    </>
                ))}
            </div>
            <footer>
                <ButtonFooter type="button" variant="outlined" onClick={handleDismiss}>
                    クリア
                </ButtonFooter>
                <ButtonFooter type="button" variant="contained" onClick={handleSubmit} disableElevation>
                    適用
                </ButtonFooter>
            </footer>
        </FilterMenuGrid>
    );
}

export function SelectScheduleFilter({ options, value, onChange }: Props) {
    const [current, setCurrent] = useState(value);

    const allOptions = options.map((v) => String(v.value));

    const select: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.checked) {
            setCurrent([...current, e.target.value]);
        } else {
            setCurrent(current.filter((v) => v !== e.target.value));
        }
    };
    const selectAll = () => {
        setCurrent(allOptions);
    };
    const unselectAll = () => {
        setCurrent([]);
    };

    const handleDismiss = () => {
        unselectAll();
        onChange([]);
    };

    const handleSubmit = () => {
        onChange(current);
    };

    return (
        <FilterMenuGrid>
            <header>
                <Button type="button" onClick={selectAll}>
                    すべて選択
                </Button>
                <Button type="button" onClick={unselectAll}>
                    選択解除
                </Button>
            </header>
            <div>
                {options.map((option) => (
                    // 課題 NEXT_SIGNAGE_CMS-119: コンテンツ管理の 削除の選択肢を非表示
                    (option.label === "削除" && option.value === "99") 
                    ? <></>
                    : <>
                        <label key={option.value}>
                            <input
                                type="checkbox"
                                value={option.value}
                                checked={current.includes(option.value)}
                                onChange={select}
                            />
                            <span>
                                {option.label}
                            </span>
                        </label>
                    </>
                ))}
            </div>
            <footer>
                <ButtonFooter type="button" variant="outlined" onClick={handleDismiss}>
                    クリア
                </ButtonFooter>
                <ButtonFooter type="button" variant="contained" onClick={handleSubmit} disableElevation>
                    適用
                </ButtonFooter>
            </footer>
        </FilterMenuGrid>
    );
}
