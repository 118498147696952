import { FormEvent, useState } from "react";
import { SchemaDefinitionMinimumFormRequest } from "../gateways/cms/schema";
import { SelectOrganization } from "./SelectOrganization";
import { SelectOrganizationModal } from "./SelectOrganizationModal";
import { SelectUserGroup } from "./SelectUserGroup";
import { SelectUserGroupModal } from "./SelectUserGroupModal";

import { styled } from "@mui/system";
import VarConst from "../styles/variables.js";
import Color from "../styles/colors.js";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import iconEdit from "../assets/icons/icon_pen.svg";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

// style
const SectionComon = styled("section")`
    h2 {
        margin: 0 0 24px;
        padding: 10px 10px 10px 40px;
         height: 40px;
        font-weight: normal;
        font-size: 20px;
        background-color: #f4f4f4;
        line-height: 1;
    }
    h2 + div {
        margin: 24px 24px 48px;

        input,
        select {
            height: 32px;
            border: ${VarConst.border1};
        }

        input[type="date"],
        input[type="time"] {
            padding-left: 0.5em;
        }

        label {
            display: flex;
            margin-bottom: 8px;

            & ~ input[type="text"],
            & + select {
                width: 100%;
            }
            & input[type="date"] {
                width: 12em;
            }
            & input[type="time"] {
                width: 6em;
            }
        }

        & > ul:nth-of-type(1) {
            display: grid;
            width: 100%;
            grid-template-columns: calc(180px + 24px) calc(180px + 24px) 1fr;

            li {
                margin-bottom: 24px;
                padding-right: 24px;

                &:nth-of-type(1) {
                    grid-column: 1/2;
                }
                &:nth-of-type(2) {
                    grid-column: 1/3;
                }
                &:nth-of-type(3) {
                    grid-column: 1/4;
                }
                &:nth-of-type(4) {
                    grid-column: 1/4;
                }

                select {
                    width: 180px;
                    margin-right: 24px;
                    margin-bottom: 8px;
                }
            }
        }
    }
`;
const ButtonSq = styled(Button)`
    &.MuiButtonBase-root {
        height: 32px;
        width: 32px;
        min-width: 32px;
        border-width: 2px;
        border-radius: 2px;

        color: transparent;
        overflow: hidden;
        user-select: none;
        cursor: pointer;

        background-color: #fff;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80%;

        * {
            color: transparent;
            white-space: nowrap;
        }
    }
`;
const ButtonEdit = styled(ButtonSq)`
    &.MuiButtonBase-root {
        background-image: url(${iconEdit});
        background-size: 75%;
    }
`;
const ButtonFooter = styled(Button)`
    &,
    &.MuiButton-outlined,
    &.MuiButton-contained {
        width: 116px;
        height: 32px;
        border-radius: 2px;
    }
    &.MuiButton-outlined {
        background-color: #fff;
        border: 2px solid #c9cbd3;
    }
    &.MuiButton-contained {
        margin-left: 16px;
        color: #f4f4f4;
        background-color: #3a3e5d;
        border-color: #3a3e5d;
    }
    &.destructive {
        color: #fd6f6c;
        width: auto;
    }
`;
const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    border-radius: 0;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;
const BarFooter = styled("footer")`
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 16px 24px;
    background-color: ${Color.bgHead};

    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100vw - 260px);

    & > div {
        align-items: end;

        div {
            display: flex;
            flex-wrap: wrap;
            width: 30em;

            label {
                line-height: 32px;
                margin-right: 1em;
                margin-bottom: 8px;
            }
            input[type="text"] {
                width: 100%;
                height: 32px;
            }
        }
    }
    button {
        width: 116px;
        height: 32px;
    }
`;

// [コンテンツスキーマ管理(編集)] フォーム
export interface Props {
    data?: SchemaDefinitionMinimumFormRequest;
    onSubmit: (data: SchemaDefinitionMinimumFormRequest) => unknown;
    onDelete?: () => unknown;
}

export function SchemaDefinitionMinimumForm({ data, onSubmit, onDelete }: Props) {
    const [title, setTitle] = useState(data?.title || "");
    const [organizations, setOrganizations] = useState(data?.organization || []);
    const [userGroups, setUserGroups] = useState(data?.usergroup || []);
    const [maxTime, setMaxTime] = useState(data?.maxTime || "");
    const [postingInterval, setPostingInterval] = useState(data?.postingInterval || "");
    const [organizationEditModalOpen, setOrganizationEditModalOpen] = useState(false);
    const [userGroupEditModalOpen, setUserGroupEditModalOpen] = useState(false);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSubmit({
            id: data?.id,
            title,
            organization: organizations,
            usergroup: userGroups,
            type: "minimum-schema",
            maxTime: maxTime,
            postingInterval: postingInterval,
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <SectionComon>
                    <h2>基本情報</h2>
                    <div>
                        <ul>
                            <li>
                                <label htmlFor="id">管理ID</label>
                                <input type="text" value={data?.id || undefined} disabled />
                            </li>
                            <li>
                                <label htmlFor="title">コンテンツタイプ名</label>
                                <input
                                    type="text"
                                    value={title}
                                    id="title"
                                    required
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </li>
                            <li>
                                <label htmlFor="organizations">利用可能テナント</label>
                                {organizations.map((organization) => (
                                    <SelectOrganization key={organization.id} value={organization.id} disabled />
                                ))}
                                <ButtonEdit type="button" onClick={() => setOrganizationEditModalOpen(true)}>
                                    編集
                                </ButtonEdit>
                            </li>
                            <li>
                                <label htmlFor="userGroups">利用可能ユーザーグループ</label>
                                {userGroups.map((userGroup) => (
                                    <SelectUserGroup key={userGroup.id} value={String(userGroup.id)} disabled />
                                ))}
                                <ButtonEdit type="button" onClick={() => setUserGroupEditModalOpen(true)}>
                                    編集
                                </ButtonEdit>
                            </li>
                        </ul>
                    </div>
                </SectionComon>

                <SectionComon>
                    <h2>スキーマ設定</h2>
                    <div>
                        <ul>
                            <li>
                                <label htmlFor="postingTime">総掲載時間</label>
                                <SelectWithIcon
                                    value={maxTime}
                                    onChange={(e) => setMaxTime(e.target.value)}
                                >
                                    <option value="">未選択</option>
                                    <option value="60">1分</option>
                                    <option value="120">2分</option>
                                    <option value="180">3分</option>
                                    <option value="240">4分</option>
                                    <option value="300">5分</option>
                                </SelectWithIcon>
                            </li>
                            <li>
                                <label htmlFor="postingInterval">掲載間隔</label>
                                <SelectWithIcon
                                    value={postingInterval}
                                    onChange={(e) => setPostingInterval(e.target.value)}
                                >
                                    <option value="">未選択</option>
                                    <option value="600">10分</option>
                                    <option value="1200">20分</option>
                                    <option value="1800">30分</option>
                                    <option value="2400">40分</option>
                                    <option value="3000">50分</option>
                                    <option value="3600">60分</option>
                                </SelectWithIcon>
                            </li>
                        </ul>
                    </div>
                </SectionComon>

                <BarFooter>
                    <div>
                        {onDelete && (
                            <ButtonFooter type="button" variant="outlined" className="destructive" onClick={onDelete}>
                                スキーマを削除
                            </ButtonFooter>
                        )}
                    </div>

                    <Stack spacing={2} direction="row">
                        <ButtonFooter type="button" variant="outlined" href="/schema-definitions">
                            キャンセル
                        </ButtonFooter>
                        <ButtonFooter type="submit" variant="contained" disableElevation disabled={!maxTime || !postingInterval}>
                            保存
                        </ButtonFooter>
                    </Stack>
                </BarFooter>
            </form>

            <SelectOrganizationModal
                open={organizationEditModalOpen}
                value={organizations}
                onChange={setOrganizations}
                onClose={() => setOrganizationEditModalOpen(false)}
            />
            <SelectUserGroupModal
                open={userGroupEditModalOpen}
                value={userGroups}
                onChange={setUserGroups}
                onClose={() => setUserGroupEditModalOpen(false)}
            />
        </>
    );
}
