import { useGetOrganizationChildrenQuery } from "../gateways/cms/organizationApi";
import { Id } from "../models/Id";

import { DeliveryGroupList } from "./DeliveryGroupList";

import { styled } from "@mui/system";

// [掲載順管理] 事業部、店舗表示

export interface Props {
    organizationId: Id;
}

// style
const SectionTitle = styled("h2")`
    margin: 0 0 24px;
    /* padding: 10px  10px  10px 40px ; */
    height: 40px;
    font-weight: normal;
    font-size: 20px;
    /* background-color: #F4F4F4; */
    line-height: 1;

    height: auto;
    padding: 16px 24px 0;
    background-color: transparent;

    & + ul {
        margin-right: 24px;
        margin-left: 24px;
    }
`;
const OrganizationList = styled("ul")`
    list-style: none outside;
    padding: 0;
    margin: 0;

    ul h2 {
        /* height: auto; */
        padding: 0 24px;
        /* background-color: transparent; */
    }
`;

export function OrganizationDeliveryGroups({ organizationId }: Props) {
    const organizations = useGetOrganizationChildrenQuery(organizationId);

    return (
        <OrganizationList>
            {organizations.data?.organizations?.map((organization) => (
                <li className="OrganizationItem" key={organization.id}>
                    <SectionTitle>{organization.name}</SectionTitle>
                    <DeliveryGroupList organizationId={organization.id} />
                    <OrganizationDeliveryGroups organizationId={organization.id} />
                </li>
            ))}
        </OrganizationList>
    );
}
