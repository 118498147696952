import { Id } from "./id";
import { BillingData } from "./billingRule";
import { SchemaDefinitionRadioItemContent } from "./schema";

export interface ArticleQuery {
    organizationId?: string;
    per?: string;
    page?: string;
    sort_key?: string;
    sort_direction?: string;
    search?: string;
    status?: string; // 複数選択の場合はカンマ区切り
    articleType?: string; // 複数選択の場合はカンマ区切り
    //displayCountPerHour?: string; // 複数選択の場合はカンマ区切り
}

export interface Article {
    id: number;
    title: string;
    status: string;
    periodDayStart: string; // yyyy-mm-dd hh:ii:ss
    periodDayEnd: string; // yyyy-mm-dd hh:ii:ss
    periodWeek: number[]; // 7つの0/1で有効・無効を表す
    periodDay: number[] | null;
    periodTimeStart: string;
    periodTimeEnd: string;
    schemaGroupId: number;
    schemaGroupTitle: string;
    articleSchema: ArticleSchemaItem[];
    displayCountPerHour: string;
    content: ArticleContent;
    delivery: DeliveryOrganization[];
    manager?: number[];
    billingInfo: ArticleBillingInfoForm[];
    duration?: number[]; // 回数保証コンテンツの場合、掲載時間
    createdBy: User;
    approvedBy: number;
    createdAt: string; // yyyy-mm-dd
    updatedAt: string; // yyyy-mm-dd
    thumbnail: string; 
    thumbnailData: string; 
    thumbnailType: string;
    fileName: string; 
}

export interface User {
    id: number;
    name: string;
}

export interface ArticleSchemaItem {
    id: number;
    fieldName: string;
    title: string;
    total?: number;
    content: ArticleSchemaContent,
}

export interface ArticleSchemaContent {
    type: string;
    required: boolean;
    info: string;
    maxTime?: number;
    postingInterval?: number;
    item?: Record<string, string>;
    value: any;
    limit?: number;
    radioItem?: SchemaDefinitionRadioItemContent;
}

export interface ArticleContent {
    type: string;
    qty?: number;
    total?: number;
    maxTime?: number;
    postingInterval?: number;
    value?: any;
}

export interface ArticleSchemaItemFileContent {
    data: string; // uri
    mimetype: string;
    filename: string;
}

export interface DeliveryOrganization {
    organizationId: number;
    name: string;
    billingName: string;
    deliveryGroup: DeliveryOrganizationDeliveryGroup[];
    schemaGroupIds: number[];
    articleType: boolean;
    index: number;
    billingMonth?: string; // yyyy-mm
    billingDatas?: BillingData[];
}

export interface DeliveryOrganizationDeliveryGroup {
    deliveryGroupId: number;
    name: string;
    checked: boolean;
}

export interface ArticleForm {
    id?: Id;
    title?: string;
    status?: number;
    articleType?: Id;
    articleSchema?: ArticleSchemaItem[];
    periodDayStart?: string | null; // yyyy-mm-dd hh:ii:ss
    periodDayEnd?: string | null; // yyyy-mm-dd hh:ii:ss
    periodWeek?: number[];
    periodDay?: number[] | null;
    periodTimeStart?: string;
    periodTimeEnd?: string;
    displayCountPerHour?: string;
    delivery?: DeliveryOrganizationForm[];
    billingInfo?: ArticleBillingInfoForm[];
    duration?: number[]; // 回数保証コンテンツの場合、掲載時間
    // 差し戻しの時だけ
    comment?: string;
    thumbnail?: string; 
    thumbnailData?: string; 
    thumbnailType?: string; 
    fileName?: string; 
    // Scheduleからコピーのときだけ
    deliveryGroupId?: number;
    // スケジュールからステータス変更のときだけ
    startDate?: string;
}

export interface OrganizationBillingRuleForm {
    organizationId: number;
    name: string;
    billingName: string;
    billingRuleId: number;
    index: number;
    billingDatas: BillingData[];
}

export interface OrganizationBillingRule {
    organizationId: number;
    name: string;
    billingName: string;
    billingRuleId: number;
    index: number;
    billingDatas: BillingData[];
}

export interface ArticleBillingInfoForm {
    id: number;
    organizationId: number;
    name: string;
    billingRuleId: number;
    billingMonth: string;
    billingName: string;
}

export interface ArticleBillingInfo {
    id: number;
    organizationId: number;
    name: string;
    billingRuleId: number;
    billingMonth: string;
    billingName: string;
}

export enum ArticleStatus {
    DRAFT = 10,
    PENDING = 20,
    REJECTED = 30,
    SCHEDULED = 40,
    PUBLISHED = 50,
    EXPIRED = 60,
    SUSPENDED = 70,
    DELETED = 99,
}

export enum ArticleStatusName {
    DRAFT = "下書き",
    PENDING = "確認中",
    REJECTED = "差戻",
    SCHEDULED = "掲載待ち",
    PUBLISHED = "掲載中",
    EXPIRED = "掲載終了",
    SUSPENDED = "一時停止中",
    DELETED = "削除",
}

export const articleStatues = [
    ArticleStatus.DRAFT,
    ArticleStatus.PENDING,
    ArticleStatus.REJECTED,
    ArticleStatus.SCHEDULED,
    ArticleStatus.PUBLISHED,
    ArticleStatus.EXPIRED,
    ArticleStatus.SUSPENDED,
    ArticleStatus.DELETED,
];

export const articleStatusNames = ["下書き", "確認中", "差戻", "掲載待ち", "掲載中", "掲載終了", "一時停止中", "削除"];

export const articleFilterStatues = [
    ArticleStatus.DRAFT,
    ArticleStatus.SCHEDULED,
    ArticleStatus.PUBLISHED,
    ArticleStatus.EXPIRED,
    //ArticleStatus.DELETED,
];

export const articleFilterStatusNames = ["下書き", "掲載待ち", "掲載中", "掲載終了"];
//export const articleFilterStatusNames = ["下書き", "掲載待ち", "掲載中", "掲載終了", "削除"];

export interface DeliveryOrganizationForm {
    organizationId: number;
    name: string;
    billingName: string;
    deliveryGroup: DeliveryOrganizationDeliveryGroupForm[];
    articleType: boolean;
    index: number;
    billingMonth?: string; // yyyy-mm
    billingDatas?: BillingData[];
}

export interface DeliveryOrganizationQuery {
    organizationId: Id;
    schemaGroupId: number;
    articleDelivery: number;
}

export interface DeliveryOrganizationDeliveryGroupForm {
    deliveryGroupId: number;
    checked: boolean;
}

export interface ArticleUpdateStatusForm {
    status: number;
    articleIds: number[];
    startDate?: string;
    deliveryGroupId?: number;
}

export interface DeliveryOrganizationUserQuery {
    organizationId: Id;
    userId: Id;
}
