import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Approval from "./pages/Approval";
import ResetMyPassword from "./pages/ResetMyPassword";
import PageNotFound from "./pages/http-errors/PageNotFound";
import { RequireAuth } from "./components/auth/RequireAuth";
import { GuestAuth } from "./components/auth/GuestAuth";
import { Articles } from "./pages/articles/Articles";
import { ArticleCreate } from "./pages/articles/ArticleCreate";
import { ArticleEdit } from "./pages/articles/ArticleEdit";
import { DeliveryGroups } from "./pages/delivery-groups/DeliveryGroups";
import { Schedule } from "./pages/delivery-groups/schedule/Schedule";
import "./App.scss";
// import * as styles from "./App.scss";
import ExampleModal from "./components/ExampleModal";
import { ArticleDuplicate } from "./pages/articles/ArticleDuplicate";
import { UserListPage } from "./pages/users/UserListPage";
// import { UserCreatePage } from "./pages/users/UserCreatePage";
import { UserEditPage } from "./pages/users/UserEditPage";
import { OrganizationListPage } from "./pages/organizations/OrganizationListPage";
import { TerminalStatusLogPage } from "./pages/logs/terminal-status/TerminalStatusLogPage";
import { DeliveryRuleDashboardPage } from "./pages/delivery-rules/DeliveryRuleDashboardPage";
import MyUserModal from "./pages/users/MyUserModal";
import { OrganizationEditPage } from "./pages/organizations/OrganizationEditPage";
import { SchemaDefinitionListPage } from "./pages/schema-definitions/SchemaDefinitionListPage";
import { SchemaDefinitionEditPage } from "./pages/schema-definitions/SchemaDefinitionEditPage";
import { SchemaDefinitionCreatePage } from "./pages/schema-definitions/SchemaDefinitionCreatePage";
import { SchemaDefinitionMinimumCreatePage } from "./pages/schema-definitions/SchemaDefinitionMinimumCreatePage";
import { ArticleApprove } from "./pages/articles/ArticleApprove";
import TerminalEditModal from "./pages/terminals/TerminalEditModal";
import TerminalDetailModal from "./pages/terminals/TerminalDetailModal";
import TerminalCreateModal from "./pages/terminals/TerminalCreateModal";
import InvitationModal from "./pages/users/InvitationModal";
import InvitationResendModal from "./pages/users/InvitationResendModal";
import { DeliveryGroupTerminalListPage } from "./pages/terminals/DeliveryGroupTerminalListPage";
import { OrganizationDeliveryGroupListPage } from "./pages/organizations/delivery-groups/OrganizationDeliveryGroupListPage";
import DeliveryGroupCreateModal from "./pages/delivery-groups/DeliveryGroupCreateModal";
import DeliveryGroupEditModal from "./pages/delivery-groups/DeliveryGroupEditModal";
import { OrganizationCreatePage } from "./pages/organizations/OrganizationCreatePage";
import { BillingDataPage } from "./pages/logs/billing-data/BillingDataPage";
import { OrganizationScheduleEditPage } from "./pages/delivery-rules/OrganizationScheduleEditPage";
import { DeliveryGroupScheduleEditPage } from "./pages/delivery-rules/DeliveryGroupScheduleEditPage";
import { TerminalScheduleEditPage } from "./pages/delivery-rules/TerminalScheduleEditPage";
import { TaskListPage } from "./pages/tasks/TaskListPage";
import { TaskPage } from "./pages/tasks/TaskPage";
import { TaskCreatePage } from "./pages/tasks/TaskCreatePage";

const App = () => {
    const location = useLocation();
    const background = (location.state as any)?.backgroundLocation;

    return (
        <>
            <Routes location={background || location}>
                {/* Guest */}
                <Route path="/login" element={<GuestAuth />}>
                    <Route index element={<Login />} />
                </Route>
                <Route path="/approval" element={<GuestAuth />}>
                    <Route index element={<Approval />} />
                </Route>
                <Route path="/reset-my-password" element={<GuestAuth />}>
                    <Route index element={<ResetMyPassword />} />
                </Route>

                {/* Authenticated */}
                <Route path="/" element={<RequireAuth />}>
                    <Route index element={<Navigate to="/articles?status=50" replace />} />

                    {/* コンテンツ管理 */}
                    <Route path="/articles" element={<Articles />} />
                    <Route path="/articles/create" element={<ArticleCreate />} />
                    <Route path="/articles/:id/edit" element={<ArticleEdit />} />
                    <Route path="/articles/:id/approve" element={<ArticleApprove />} />

                    {/* 掲載順管理 */}
                    <Route path="/delivery-groups" element={<DeliveryGroups />} />
                    <Route path="/delivery-groups/:id" element={<Schedule />} />

                    {/* ユーザー管理 */}
                    <Route path="/users" element={<UserListPage />} />
                    {/* <Route path="/users/create" element={<UserCreatePage />} /> */}
                    <Route path="/users/:id/edit" element={<UserEditPage />} />

                    {/* テナント管理 */}
                    <Route path="/organizations" element={<OrganizationListPage />} />
                    <Route path="/organizations/create" element={<OrganizationCreatePage />} />
                    <Route path="/organizations/:id/edit" element={<OrganizationEditPage />} />

                    <Route
                        path="/organizations/:organizationId/delivery-groups"
                        element={<OrganizationDeliveryGroupListPage />}
                    >
                        <Route path=":deliveryGroupId/terminals" element={<DeliveryGroupTerminalListPage />} />
                    </Route>

                    {/* ログ */}
                    <Route path="/logs/terminal-status" element={<TerminalStatusLogPage />} />

                    {/* 稼働スケジュール管理 */}
                    <Route path="/delivery-rules" element={<DeliveryRuleDashboardPage />} />
                    <Route
                        path="/delivery-rules/organizations/:organizationId"
                        element={<OrganizationScheduleEditPage />}
                    />
                    <Route
                        path="/delivery-rules/delivery-groups/:deliveryGroupId"
                        element={<DeliveryGroupScheduleEditPage />}
                    />
                    <Route path="/delivery-rules/terminals/:terminalId" element={<TerminalScheduleEditPage />} />

                    {/* コンテンツスキーマ管理 */}
                    <Route path="/schema-definitions" element={<SchemaDefinitionListPage />} />
                    <Route path="/schema-definitions/create" element={<SchemaDefinitionCreatePage />} />
                    <Route path="/schema-definitions/minimum/create" element={<SchemaDefinitionMinimumCreatePage />} />
                    <Route path="/schema-definitions/:id/edit" element={<SchemaDefinitionEditPage />} />

                    {/* 請求データ */}
                    <Route path="/logs/billing-data" element={<BillingDataPage />} />

                    {/* タスク管理 */}
                    <Route path="/tasks" element={<TaskListPage />} />
                    <Route path="/tasks/:id" element={<TaskPage />} />
                    <Route path="/tasks/create" element={<TaskCreatePage />} />
                </Route>

                {/* No Match */}
                <Route path="*" element={<PageNotFound />} />
            </Routes>

            {background && (
                <Routes>
                    <Route path="/example-modal" element={<ExampleModal />} />
                    <Route path="/articles/:id/duplicate" element={<ArticleDuplicate />} />

                    <Route path="/users/me" element={<MyUserModal />} />

                    <Route path="/invitations/create" element={<InvitationModal />} />
                    <Route path="/invitations/resend" element={<InvitationResendModal />} />

                    <Route path="/organizations/:organizationId/terminals/create" element={<TerminalCreateModal />} />
                    <Route path="/organizations/:organizationId/terminals/:id/edit" element={<TerminalEditModal />} />
                    <Route path="/organizations/:organizationId/terminals/:id/detail" element={<TerminalDetailModal />} />

                    <Route
                        path="/organizations/:organizationId/delivery-groups/create"
                        element={<DeliveryGroupCreateModal />}
                    />
                    <Route path="/delivery-groups/:id/edit" element={<DeliveryGroupEditModal />} />
                </Routes>
            )}
        </>
    );
};

export default App;
