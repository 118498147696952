import { MultiSelect } from "react-multi-select-component";
import { useState, FormEventHandler } from "react";
import { useGetSchemaTypeDefinitionsQuery } from "../gateways/cms/schemaApi";

import { styled } from "@mui/system";
import ButtonMUI from "@mui/material/Button";
import iconSearch from "../assets/icons/icon_search.svg";
import iconSelectDown from "../assets/icons/icon_arrow_down.svg";

export interface Props {
    value: string;
    onChange: (value: string) => void;
    defaultOption?: JSX.Element;
    isEdit: boolean;
}

export interface ScheduleProps {
    value: string[];
    onChange: (value: OptionType[]) => void;
    defaultOption?: JSX.Element;
}

interface OptionType {
    value: number;
    label: string;
}

const FormSelect= styled("form")`
        display: flex;
        margin-bottom: 16px;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 4px;
            display: block;
            height: 24px;
            width: 24px;
            background-image: url(${iconSearch});
            background-repeat: no-repeat;
            background-position: center center;
            transform: translate(0, -50%);
        }
    `;
const SelectWithIcon = styled("select")`
    appearance: none;
    padding-right: 1.8em;
    background: transparent url(${iconSelectDown}) right center no-repeat scroll;
`;

const SelectWithCheck = styled(MultiSelect)`
    z-index: 10;
    position: relative;
    appearance: none;
    padding-right: 0.2em;
    width: 400px;
`;
const ButtonSearch = styled(ButtonMUI)`
        height: 40px;
        position: relative;
        border-radius: 2px;
`;

export function SelectArticleType({ value, onChange, defaultOption, isEdit }: Props) {
    const articleTypes = useGetSchemaTypeDefinitionsQuery();
    return (
        <SelectWithIcon value={value} onChange={(e) => onChange(e.target.value) } disabled={isEdit}>
            {defaultOption}
            {articleTypes.data?.map((t) => (
                <option key={t.id} value={t.id}>
                    {t.title}
                </option>
            ))}
        </SelectWithIcon>
    );
}

export function SelectScheduleArticleType({ value,  onChange }: ScheduleProps) {
    const handleSubmit: FormEventHandler<HTMLFormElement> = () => {
        onChange(selected);
        value = selected.map((t: OptionType) => (String(t.value)));
    };

    const articleTypes = useGetSchemaTypeDefinitionsQuery();
    const options: OptionType[] = articleTypes.data?.map((t) => ({ value: t.id, label: t.title })) || [];
    const [selected, setSelected] = useState([]);
    return (
        <FormSelect onSubmit={handleSubmit}>
            
            <SelectWithCheck
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="コンテンツタイプ選択"
                disableSearch={true}
                hasSelectAll={false}
            >
            </SelectWithCheck>
            <ButtonSearch type="submit" variant="contained" disableElevation >
                コンテンツタイプ選択
            </ButtonSearch>
        </FormSelect>

    );
}
