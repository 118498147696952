import { ChangeEvent, useState } from "react";
import { useSearchParams, NavLink } from "react-router-dom";
import { SearchForm } from "../../components/SearchForm";
import { useDeleteOrganizationsMutation, useGetOrganizationsQuery } from "../../gateways/cms/organizationApi";
import { Organization } from "../../gateways/cms/organization";
import MainLayout from "../../layouts/MainLayout";
import { ArticleSortMenu } from "../../components/ArticleSortMenu";
import { OrganizationEditMenu } from "../../components/OrganizationEditMenu";
import { confirmWithStyle } from "../../components/MyConfirm";
import Stack from "@mui/material/Stack";
import ButtonMUI from "@mui/material/Button";

import { styled } from "@mui/system";
import { makeStyles } from "@material-ui/styles";
import Color from "../../styles/colors.js";
import VarConst from "../../styles/variables.js";
import iconSort from "../../assets/icons/icon_sort.svg";
import iconEllipsis from "../../assets/icons/icon_ellipsis.svg";
import iconCheckboxOff from "../../assets/icons/icon_checkbox_off.svg";
import iconCheckboxOn from "../../assets/icons/icon_checkbox_on.svg";
import icon_checkbox_disabled from "../../assets/icons/icon_checkbox_disabled.svg";
import { PaginationLinks } from "../../components/PaginationLinks";

// style
const varCss = {
    articleListStaticItemsW: "260px + 24px + 3em + 8em + 8em + 3em + 24px",
};
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
    }
`;
const PageMain = styled("section")`
    display: flex;
    flex-direction: column;
    padding: 0 24px 0;
    overflow: hidden;
`;
const SearchContent = styled(SearchForm)`
    margin-bottom: 16px;
`;
const BarList = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;

    [class$="MuiStack-root"]{
        align-items: center;

        & > :not(style) + :not(style) {
            margin-left: 16px;
        }
    }
`;
const WrapperSticky = styled("section")`
    position: relative;
    overflow: auto;
    margin-bottom: 24px;
`;
const TableCommon = styled("table")`
    table-layout: fixed;
    border-spacing: 0;
    height: 100%;
    margin: 0;
    border-left: ${VarConst.border1};
    border-bottom: ${VarConst.border1};

    font-size: 13px;

    thead {
        position: sticky;
        top: 0;
        z-index: 10;
    }

    tr > th,
    tr > td {
        border-top: ${VarConst.border1};
        border-right: ${VarConst.border1};
        line-height: 1.1;

        &.checkbox {
            min-width: 3em; // 固定
            max-width: 3em; // 固定
            position: relative;

            input[type="checkbox"] {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 24px;
                height: 24px;
                margin: 0;
                opacity: 0;
                cursor: pointer;
                transform: translate( -50%, -50%);
                
                & + span::before {
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    pointer-events: none;
                    background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
                }
                &:checked + span::before {
                    background-image: url(${iconCheckboxOn});
                }
                &:disabled + span::before {
                    background: transparent url(${icon_checkbox_disabled}) scroll no-repeat center center;
                }
            }
        }
        &.id {
            min-width: 8em; // 固定
            max-width: 8em; // 固定
        }
        &.name {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.15);
        }
        &.child_organization {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.25);
        }
        &.manager {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.15);
        }
        &.user {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.15);
        }
        &.delivery_group {
            width: calc((100vw - (${varCss.articleListStaticItemsW})) * 0.3);
        }
        &.updated {
            min-width: 8em;
            max-width: 8em;
        }
        &.function {
            min-width: 3em; // 固定
            max-width: 3em; // 固定
        }
    }
    tr > th {
        height: 32px;
        padding: 4px 0 4px 8px;
        background-color: ${Color.bgHead};
        text-align: left;
        font-weight: normal;
        border-bottom: ${VarConst.border1};
        
        b{
            font-weight: normal;
        }

        &.function {
            text-align: center;
            padding: 0;
        }
    }
    tr > td {
        height: 100%;
        padding: 4px 8px;

        ul {
            /* display: flex; */
            li {
                display: inline;
            }
            li + li::before {
                content: ", ";
            }
        }

        &.function {
            padding-top: 12px;
            padding-bottom: 12px;

            button {
                // IconButtonに hrefがないので Buttonを使用
                height: 24px;
                width: 24px;
                min-width: 24px;
                border: ${VarConst.border1};
                border-width: 2px;
                border-radius: 2px;
                background-image: url(${iconEllipsis});
                background-repeat: no-repeat;
                background-position: center center;
                background-color: #fff;
            }
        }
    }

    // 状態表示
    tr {
        .status div {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 2em;
            margin: 0 auto;
            color: #fff;
            line-height: 1;

            b {
                writing-mode: vertical-lr;
                font-weight: normal;
            }
        }
    }
`;
const FunctionHeader = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
        flex: 1;
        font-weight: normal;
    }
    button {
        display: block;
        padding: 0;
        margin: 0;
        height: 24px;
        width: 24px;
        border: ${VarConst.border0};
        overflow: hidden;
        color: transparent;
        background-image: url(${iconSort});
        background-repeat: no-repeat;
        background-position: center center;
        background-color: transparent;

        &.MuiButton-root {
            min-width: 24px;
        }
    }
`;

export function OrganizationListPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [selected, setSelected] = useState([] as number[]);
    const organizations = useGetOrganizationsQuery({
        sort_key: searchParams.get("sort_key") || undefined,
        sort_direction: searchParams.get("sort_direction") || undefined,
        search: searchParams.get("q") || undefined,
    });
    const [organizationBulkDelete] = useDeleteOrganizationsMutation();

    // const handleDeleteOrganization = (organizationId: number) => async () => {
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!confirm("組織を削除してもよろしいですか？")) return;
    //     await organizationBulkDelete([organizationId]);
    // };
    const handleDeleteOrganization = async (id: number) => {
        const isConfirmed = await confirmWithStyle({
            title: "削除",
            body: "テナントのデータを削除します。よろしいですか？",
            okButtonText: "削除",
            destructive: true,
        });
        if (isConfirmed) {
            await organizationBulkDelete([id]).unwrap();
            organizations.refetch();
        }
    };

    const handleSortBy =
        (key: string, direction: string = "asc") =>
        () => {
            searchParams.set("sort_key", key);
            searchParams.set("sort_direction", direction);
            setSearchParams(searchParams);
        };

    const search = (q: string) => {
        searchParams.set("q", q);
        setSearchParams(searchParams);
    };

    const disabledTitle = (organization: Organization) => {
        if(organization.childOrganization?.length > 0){
            return "所属テナントが存在するため削除できません";
        }
        else{
            return "";
        }
    };

    const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSelected([...selected, Number(e.target.value)]);
        } else {
            setSelected(selected.filter((id) => id !== Number(e.target.value)));
        }
    };

    const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
        let selectOrg :number[]= [];
        if (e.target.checked) {
            organizations.data?.data?.forEach((organization) => {
                if(!organization.childOrganization){
                    selectOrg.push(Number(organization.id)); 
                }
            });
            setSelected(selectOrg);
        } else {
            setSelected([]);
        }
    };

    // const bulkDelete = async () => {
    //     // eslint-disable-next-line no-restricted-globals
    //     if (!confirm("選択した組織を削除してもよろしいですか？")) return;
    //     // TODO: 専用のAPIを用意してもらった方がいい
    //     await organizationBulkDelete(selected);
    //     setSelected([]);
    // };
    const handleOrganizationBulkDelete = async () => {
        const isConfirmed = await confirmWithStyle({
            title: "削除",
            body: "選択したテナントを削除してもよろしいですか？",
            okButtonText: "削除",
            destructive: true,
        });
        if (isConfirmed) {
            // TODO: 専用のAPIを用意してもらった方がいい
            await organizationBulkDelete(selected);
            setSelected([]);
            organizations.refetch();
        }
    };

    //const ButtonOrganization = styled(ButtonMUI)`
    //    height: 32px;
    //    border-radius: 2px;
    //`;
    const useStyles = makeStyles({
        createButton: {
            height: '32px',
            borderRadius: '2px',
        },
    });
    const buttonClasses = useStyles();

    // DEBUG
    // console.log("organizations: ");
    // console.log(organizations.data);

    return (
        <MainLayout title="テナント管理">
            <PageTitle>
                <h1>テナント管理</h1>
                <ButtonMUI className={buttonClasses.createButton} variant="contained" disableElevation component={NavLink} to="/organizations/create">
                    新規作成
                </ButtonMUI>
            </PageTitle>

            <PageMain>
                <SearchContent
                    defaultValue={searchParams.get("q") || ""}
                    onSubmit={search}
                    placeholder="キーワード検索"
                />

                <BarList>
                    <Stack spacing={1} direction="row">
                        <ButtonMUI type="button" variant="text" onClick={handleOrganizationBulkDelete}>
                            削除
                        </ButtonMUI>
                    </Stack>

                    <Stack spacing={1} direction="row">
                        <span> 選択数: {selected.length} </span>
                        <span> 登録件数: {organizations.data?.data?.length} </span>
                        <span>
                            {organizations.data && <PaginationLinks meta={organizations.data} />}
                        </span>
                    </Stack>
                </BarList>

                <WrapperSticky>
                    <TableCommon>
                        <thead>
                            <tr>
                                <th className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selected.length > 0}
                                        onChange={handleSelectAll}
                                    /><span></span>    
                                </th>
                                <th className="id">
                                    <b>ID</b>
                                </th>
                                <th className="name">
                                    <FunctionHeader>
                                        <b>テナント名</b>
                                        <ArticleSortMenu onSubmit={handleSortBy("name")} sortKey="value" />
                                    </FunctionHeader>
                                </th>
                                <th className="child_organization">
                                    <b>所属テナント</b>
                                </th>
                                <th className="manager">
                                    <b>テナント担当者</b>
                                </th>
                                <th className="user">
                                    <b>テナント所属ユーザー</b>
                                </th>
                                <th className="delivery_group">
                                    <b>配信グループ</b>
                                </th>

                                <th className="updated">
                                    <FunctionHeader>
                                        <b>更新日時</b>
                                        <ArticleSortMenu onSubmit={handleSortBy("updatedAt")} sortKey="value" />
                                    </FunctionHeader>
                                </th>

                                <th className="function">操作</th>
                                {/*
                                <button type="button" onClick={handleSortBy("name")}>
                                    sort by name asc
                                </button>
                                <button type="button" onClick={handleSortBy("name", "desc")}>
                                    sort by name desc
                                </button>
                                <button type="button" onClick={handleSortBy("updatedAt")}>
                                    sort by updatedAt asc
                                </button>
                                <button type="button" onClick={handleSortBy("updatedAt", "desc")}>
                                    sort by updatedAt desc
                                </button>
                             */}
                            </tr>
                        </thead>

                        <tbody>
                            {organizations.data?.data?.map((organization) => (
                                <tr key={organization.id}>
                                    <td className="checkbox">
                                        <input
                                            type="checkbox"
                                            value={organization.id}
                                            checked={selected.includes(organization.id) && organization.childOrganization === null}
                                            onChange={handleSelect}
                                            disabled={organization.childOrganization?.length > 0}
                                            title={disabledTitle(organization)}
                                        /><span></span>
                                    </td>
                                    <td className="id">{organization.id}</td>
                                    <td className="name">{organization.name}</td>
                                    <td className="child_organization">
                                        <ul>
                                            {organization.childOrganization?.map((childOrgItem) => (
                                                    <li key={childOrgItem.id}>
                                                        {childOrgItem.name}
                                                    </li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td className="manager">
                                        <ul>
                                            {organization.manager?.map((managerItem) => (
                                                <li key={managerItem.id}>{managerItem.name}</li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td className="user">
                                        <ul>
                                            {organization.user?.map((userItem) => (
                                                <li key={userItem.id}>{userItem.name}</li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td className="delivery_group">
                                        <ul>
                                            {organization.deliveryGroup?.map((deliveryGroupItem) => (
                                                <li key={deliveryGroupItem.id}>{deliveryGroupItem.name}</li>
                                            ))}
                                        </ul>
                                    </td>
                                    <td className="updated">{organization.updatedAt.replace(/-/g, "/")}</td>
                                    <td className="function">
                                        {/*
                                        <Link to={`/organizations/${organization.id}/edit`}>編集</Link>
                                        <button type="button" onClick={handleDeleteOrganization(organization.id)}>
                                            削除
                                        </button>
                                         */}
                                        <OrganizationEditMenu
                                            organization={organization}
                                            onOrganizationDelte={handleDeleteOrganization}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </TableCommon>
                </WrapperSticky>
            </PageMain>
        </MainLayout>
    );
}
