import { useGetSchemaTypeDefinitionsQuery } from "../gateways/cms/schemaApi";
import { SelectFilter } from "./SelectFilter";

export interface Props {
    value: string[];
    onChange: (value: string[]) => void;
}

export function SelectArticleTypeFilter({ value, onChange }: Props) {
    const articleTypes = useGetSchemaTypeDefinitionsQuery();
    const filters = articleTypes.data?.map((t) => ({ value: String(t.id), label: t.title })) || [];

    return <SelectFilter options={filters} value={value} onChange={onChange} />;
}
