import TerminalStatusCard from "./TerminalStatusCard";

import { styled } from "@mui/system";
import Box from "@material-ui/core/Box";
//import ButtonMUI from "@mui/material/Button";
import { TerminalStatusLogEntry } from "../gateways/cms/log";
import { useGetMyUserQuery } from "../gateways/cms/userApi";

// style
const GroupList = styled("section")`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    padding: 0 24px;
`;
const GroupBox = styled(Box)`
    position: relative;
    width:calc(100% / 5 - 10px);
    min-width:250px;
    height: 445px;
    margin: 0 10px 10px 0;

    & > .MuiButton-root {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
    }
    &:hover > .MuiButton-root {
        opacity: 1;
        pointer-events: unset;
    }
`;
const GroupUserBox = styled(Box)`
    position: relative;
    width:calc(100% / 5 - 10px);
    min-width:250px;
    height: 405px;
    margin: 0 10px 10px 0;

    & > .MuiButton-root {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
    }
    &:hover > .MuiButton-root {
        opacity: 1;
        pointer-events: unset;
    }
`;
/** タスク追加 無効のためコメントアウト
const GroupButton = styled(ButtonMUI)`
    &,
    &.MuiButton-contained {
        height: 24px;
        width: 80px;
        border-radius: 2px;
        font-size: 12px;
        padding: 0;
    }
`;
** コメントアウトここまで **/

export interface Props {
    entry: TerminalStatusLogEntry;
}

export function OrganizationTerminalStatusLog({ entry }: Props) {
    // ユーザー情報取得
    const user = useGetMyUserQuery();
    if (!user.data) {
        return <div>loading</div>;
    };

    return (
        
        <GroupList>
            {user.data?.checkAdmin ?  
                (entry.organization.terminals.map((terminal) => (
                    <GroupBox key={entry.organization.id}>
                        <TerminalStatusCard
                            title={terminal.deliveryName}
                            subheader={terminal.name}
                            message={terminal.info.message}
                            memo={terminal.memo}
                            background={convertErrorLevel(terminal.info.errorUpdate)}
                            image={terminal.info.screenshotUri}
                            datetime={terminal.info.updatedAt}
                            organizationId={entry.organization.id}
                            terminalId={terminal.id}
                            checkAdmin={true}
                        />
                    </GroupBox>
            ))):
                (entry.organization.terminals.map((terminal) => (
                    <GroupUserBox  key={entry.organization.id}>
                        <TerminalStatusCard
                            title={terminal.deliveryName}
                            subheader={terminal.name}
                            message={terminal.info.message}
                            memo={terminal.memo}
                            background={convertErrorLevel(terminal.info.errorUpdate)}
                            image={terminal.info.screenshotUri}
                            datetime={terminal.info.updatedAt}
                            organizationId={entry.organization.id}
                            terminalId={terminal.id}
                            checkAdmin={false}
                        />
                    </GroupUserBox>
            )))}
        </GroupList>
    );
}

function convertErrorLevel(level: string): "error" | "normal" | "off" {
    switch (level) {
        case "error":
        case "Error":
        case "Critical":
        case "Alert":
        case "Emergency":
            return "error";
        case "off":
            return "off";
        default:
            return "normal";
    }
}
