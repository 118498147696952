import { useRef } from "react";
import { ChangeEventHandler, ChangeEvent } from "react";
import { useGetDeliveryGroupsQuery, useGetUserDeliveryGroupsQuery } from "../gateways/cms/deliveryGroupApi";
import { DeliveryGroup } from "../gateways/cms/deliveryGroup";
import { Id } from "../models/Id";

import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ButtonMUI from "@mui/material/Button";
import iconCheckboxOff from "../assets/icons/icon_checkbox_off.svg"
import iconCheckboxOn from "../assets/icons/icon_checkbox_on.svg"

// [コンテンツ編集] 配信先 選択

// style
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;
const GridButtonBottom = styled(Grid)`
    position: relative;

    div {
        position: absolute;
        bottom: 0;
    }
`;
const GridDeliveryGroup = styled(Grid)`
    label:last-child {
        padding-left: 16px;
        // margin-bottom: 0;
        /* input[type='checkbox']{
            margin-right: 0.75em;
        } */
    }
`;
const LabelCheckbox = styled( 'label' )`

    display: flex;
    align-items: center;
    position: relative;

    input[type="checkbox"]{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        transform: translate( -50%, -50%);

        /* &::before{
            content: "";
            display: block;
            width:  24px;
            height: 24px;
            
            background-color: transparent;
            background-attachment: scroll;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(${iconCheckboxOff});

            transform: translate(0, -50%);
        }
        &:checked::before{
            background-image: url(${iconCheckboxOn});
        } */

        & + span{
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 0.5em;
                pointer-events: none;
                background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
            }
        }
        &:checked + span::before {
            background-image: url(${iconCheckboxOn});
        }

    }
`;
const LabelUnCheckbox = styled( 'label' )`

    display: flex;
    align-items: center;
    position: relative;
    color: silver;

    input[type="checkbox"]{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 24px;
        height: 24px;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        transform: translate( -50%, -50%);

        /* &::before{
            content: "";
            display: block;
            width:  24px;
            height: 24px;
            
            background-color: transparent;
            background-attachment: scroll;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(${iconCheckboxOff});

            transform: translate(0, -50%);
        }
        &:checked::before{
            background-image: url(${iconCheckboxOn});
        } */

        & + span{
            display: flex;
            align-items: center;

            &::before {
                content: "";
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 0.5em;
                pointer-events: none;
                background: transparent url(${iconCheckboxOff}) scroll no-repeat center center;
            }
        }
        &:checked + span::before {
            background-image: url(${iconCheckboxOn});
        }

    }
`;

export interface Props {
    organizationId: Id;
    value: string[];
    onChange: (deliveryGroupIds: string[]) => void;
}
export interface UserProps {
    organizationId: Id;
    userId: Id;
    //value: string[];
    onChange: (deliveryGroups: DeliveryGroup[]) => void;
}

export const SelectUserDeliveryGroup = ({ organizationId, userId, onChange }: UserProps) => {
    const deliveryGroups = useGetUserDeliveryGroupsQuery({organizationId, userId});
    const userDeliveries = useRef<DeliveryGroup[]>(deliveryGroups.data || []);
    userDeliveries.current = [...deliveryGroups.data || []];

    const handleChange = (index:number) => (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            userDeliveries.current.forEach((userDelivery, i) => {
                let delivery = {
                    organizationId: userDelivery.organizationId,
                    id: userDelivery.id,
                    name: userDelivery.name,
                    terminals: userDelivery.terminals,
                    invalid: false
                };

                if(i === index){
                    userDeliveries.current[i] = delivery;
                };
                onChange(userDeliveries.current);
            });
        } else {
            userDeliveries.current.forEach((userDelivery, i) => {
                let delivery = {
                    organizationId: userDelivery.organizationId,
                    id: userDelivery.id,
                    name: userDelivery.name,
                    terminals: userDelivery.terminals,
                    invalid: true
                };

                if(i === index){
                    userDeliveries.current[i] = delivery;
                };
                onChange(userDeliveries.current);
            });
        }
    };

    const selectAll = () => {
        onChange([]);
    };

    const unselectAll = () => {
        onChange([]);
    };

    const checkedValue = (userDeliveries: DeliveryGroup[] | undefined, index: number) => {
        if(userDeliveries === undefined){
            return true;
        }else if(userDeliveries[index].invalid === undefined){
            return true;
        }else if(userDeliveries[index].invalid === false){
            return true;
        }else{
            return false;
        }
    }

    return (
        <>
            <GridButtonBottom item xs={6}>
                <Stack spacing={2} direction="row">
                    <Button type="button" onClick={() => selectAll()}>
                        すべて選択
                    </Button>
                    <Button type="button" onClick={() => unselectAll()}>
                        選択解除
                    </Button>
                </Stack>
            </GridButtonBottom>

            {deliveryGroups.data?.map((deliveryGroup, index) => (
                <GridDeliveryGroup item xs={6} key={deliveryGroup.id}>
                    <LabelCheckbox key={deliveryGroup.id}>
                        <input
                            type="checkbox"
                            value={deliveryGroup.id}
                            defaultChecked={checkedValue(userDeliveries.current, index)}
                            onChange={handleChange(index)}
                        />
                        <span>{deliveryGroup.name}</span>
                        <span>({deliveryGroup.terminals.map((t) => t.name).join(",")})</span>
                    </LabelCheckbox>
                </GridDeliveryGroup>
            ))}
        </>
    );
};

export const SelectOrganizationDeliveryGroup = ({ organizationId, value, onChange }: Props) => {
    const deliveryGroups = useGetDeliveryGroupsQuery(organizationId);

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (e.target.checked) {
            onChange([...value, e.target.value]);
        } else {
            onChange(value.filter((id) => id !== e.target.value));
        }
    };

    const selectAll = () => {
        onChange((deliveryGroups.data || []).map((d) => String(d.id)));
    };

    const unselectAll = () => {
        onChange([]);
    };

    return (
        <>
            <GridButtonBottom item xs={6}>
                <Stack spacing={2} direction="row">
                    <Button type="button" onClick={() => selectAll()}>
                        すべて選択
                    </Button>
                    <Button type="button" onClick={() => unselectAll()}>
                        選択解除
                    </Button>
                </Stack>
            </GridButtonBottom>

            {deliveryGroups.data?.map((deliveryGroup) => (
                <GridDeliveryGroup item xs={6} key={deliveryGroup.id}>
                    <LabelCheckbox key={deliveryGroup.id}>
                        <input
                            type="checkbox"
                            value={deliveryGroup.id}
                            checked={value.includes(String(deliveryGroup.id))}
                            onChange={handleChange}
                        />
                        <span>{deliveryGroup.name}</span>
                        <span>({deliveryGroup.terminals.map((t) => t.name).join(",")})</span>
                    </LabelCheckbox>
                </GridDeliveryGroup>
            ))}
        </>
    );
};

export const UnSelectOrganizationDeliveryGroup = ({ organizationId, value, onChange }: Props) => {
    const deliveryGroups = useGetDeliveryGroupsQuery(organizationId);
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange([]);
    };

    return (
        <>
            <GridButtonBottom item xs={6}>
                <Stack spacing={2} direction="row">

                </Stack>
            </GridButtonBottom>
            {deliveryGroups.data?.map((deliveryGroup) => (
                <GridDeliveryGroup item xs={6} key={deliveryGroup.id}>
                    <LabelUnCheckbox key={deliveryGroup.id}>
                        <input
                            type="checkbox"
                            value={deliveryGroup.id}
                            checked={false}
                            onChange={handleChange}
                        />
                        <span>{deliveryGroup.name}</span>
                        <span>({deliveryGroup.terminals.map((t) => t.name).join(",")})</span>
                    </LabelUnCheckbox>
                </GridDeliveryGroup>
            ))}
        </>
    );
};
