import { ArticleStatus } from "../../gateways/cms/article";
import { useUpdateArticleMutation } from "../../gateways/cms/articleApi";

export function useArticleDraft() {
    const [articleUpdate] = useUpdateArticleMutation();

    // 下書きにする
    const articleMakeDraft = (articleId: number) => {
        articleUpdate({
            id: articleId,
            status: ArticleStatus.DRAFT,
        });
    };

    return articleMakeDraft;
}
