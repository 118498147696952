import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ArticleApproveForm } from "../../components/ArticleApproveForm";
import { ArticleForm } from "../../components/ArticleForm";
import { Article, ArticleStatus } from "../../gateways/cms/article";
import { useGetArticleQuery, useUpdateArticleMutation } from "../../gateways/cms/articleApi";
import { useGetMyUserQuery } from "../../gateways/cms/userApi";
import MainLayout from "../../layouts/MainLayout";
import { selectOrganizationId } from "../../features/cms/organizationBreadcrumbSlice";

import { styled } from "@mui/system";

// style
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    // margin-bottom: 16px;

    h1 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;

        span {
            margin-right: 1em;
        }
    }
`;
const ArticleApproveData = styled("section")`
    flex: 1;
    overflow: auto;

    label,
    input,
    textarea,
    select,
    button,
    a {
        pointer-events: none;
    }
    input[type="checkbox"],
    input[type="radio"],
    button {
        opacity: 0.5;
    }

    footer {
        display: none;
    }
`;

export const ArticleApprove = () => {
    const { id } = useParams() as { id: string };
    const navigate = useNavigate();
    const article = useGetArticleQuery(id);
    const [articleUpdate] = useUpdateArticleMutation();
    const organizationId = useSelector(selectOrganizationId);
    const user = useGetMyUserQuery();
    const [checkSaveAs, setSaveAs] = useState(false);

    if (!article.data) {
        return <div>loading...</div>;
    };

    const handleReject = async (comment: string) => {
        await articleUpdate({
            id,
            status: ArticleStatus.REJECTED,
            comment,
        });
        navigate("/articles?status=50");
    };

    const handleAccept = async () => {
        setSaveAs(true);
        await articleUpdate({
            id,
            status: ArticleStatus.PUBLISHED,
        });
        navigate("/articles?status=50");
    };

    const pageTitle = (article: Article):string => {
        const editTitle = "コンテンツ編集";
        const detailTitle = "コンテンツ詳細";
        if(user.data?.checkAdmin) return editTitle;
        if(article.createdBy.id === user.data?.id) return editTitle; 
        return detailTitle;
    };

    return (
        <MainLayout title="コンテンツ詳細・編集">
            <PageTitle>
                <h1>{pageTitle(article.data)}</h1>
            </PageTitle>
            {/* A010 コンテンツ管理編集の情報を表示（編集不可） */}
            <ArticleApproveData>
                {article.data && <ArticleForm article={article.data} articleApprove={true} organizationId={organizationId} checkSaveAs={checkSaveAs} isEdit={true}/>}
            </ArticleApproveData>

            <ArticleApproveForm onAccept={handleAccept} onReject={handleReject} />
        </MainLayout>
    );
};
